import { useState } from 'react'
import Section from 'components/Section'
import { useMemoizedContentGetter } from 'utils/contentful'
import { Grid, Button } from '@ffn/sunbeam'
import { Typography, MediaImage } from 'components/Contentful'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import { BLOCKS } from '@contentful/rich-text-types'
import { ChevronUp, ChevronDown } from 'react-feather'
import useTheme from 'hooks/useTheme'
import styles from './FeaturedStory.module.scss'

export const FeaturedStory = ({ content }) => {
  const { sectionTitle, title, bodyCopy, featuredStoryimage, quote } = useMemoizedContentGetter(
    content,
    ['sectionTitle', 'title', 'bodyCopy', 'featuredStoryimage', 'quote']
  )
  const isMobile = useViewportSmallerThan(BREAKPOINTS.md)
  const [expanded, setExpanded] = useState(false)
  const theme = useTheme()
  const mediaImageContent = !isMobile
    ? featuredStoryimage?.mediaContent
    : featuredStoryimage?.mobileMediaContent || featuredStoryimage?.mediaContent

  const collapseHandler = () => {
    setExpanded((wasExpanded) => !wasExpanded)
  }

  return (
    <Section
      data-testid="featured-story-section"
      className={styles['section']}
      backgroundColor={theme?.sb?.colors?.neutral?.grey?.['8']}
    >
      <Grid container justifyContent="center">
        <Grid
          item
          sm={10}
          md={10}
          lg={10}
          xl={8}
          xxl={6}
          xs={11}
          justifyContent="space-around"
          data-testid="featured-story-title-container"
        >
          <Typography
            data-testid="featured-story-eyebrow"
            className={styles['eyebrow']}
            content={sectionTitle?.textContent}
            variant="displayXS30"
            fontWeight="bold"
            component="h3"
          />
          <Typography
            data-testid="featured-story-title"
            className={styles['title']}
            content={title?.textContent}
            variant={isMobile ? 'displayS30' : 'displayM20'}
            sx={{ margin: 0 }}
            fontWeight="bold"
            component="h2"
          />
        </Grid>
      </Grid>
      <Grid container data-testid="featured-story-grid-main-container">
        <Grid
          data-testid="featured-story-grid-container"
          container
          className={styles['card-grid']}
          spacing={0}
        >
          <Grid
            data-testid="featured-story-left"
            className={`${styles['grid-item']} ${styles['body']}`}
            item
            xs={12}
            lg={6}
            order={{ xs: 2, lg: 1 }}
          >
            <div className={isMobile && !expanded ? styles['small-body'] : undefined}>
              <Typography
                data-testid="featured-story-body"
                content={bodyCopy?.textContent}
                variant="bodyS30"
                component="p"
              />
            </div>
            {isMobile && (
              <Button
                endIcon={expanded ? <ChevronUp /> : <ChevronDown />}
                className={styles['collapse-button']}
                onClick={collapseHandler}
                variant="text"
                data-testid="featured-story-expand-button"
              >
                {expanded ? 'Show less' : 'Show more'}
              </Button>
            )}
          </Grid>
          <Grid
            data-testid="featured-story-left"
            className={styles['grid-item']}
            item
            xs={12}
            lg={6}
            order={{ xs: 1, lg: 2 }}
          >
            {mediaImageContent && (
              <Grid item xs={12} lg={12}>
                <div className={styles['block-img']}>
                  <MediaImage
                    layout="fill"
                    objectFit="cover"
                    objectPosition="top right"
                    priority={true}
                    quality={isMobile ? 75 : 100}
                    content={mediaImageContent}
                    alt={featuredStoryimage?.mediaAltText}
                  />
                  <div className={styles['content']}>
                    <span className={styles['open-quote']}>“</span>
                    <Typography
                      content={quote?.textContent}
                      color={theme?.sb?.colors?.neutral?.white}
                      fontWeight="regular"
                      variantOverride={{
                        [BLOCKS.PARAGRAPH]: !isMobile ? 'displayM30' : 'displayM10',
                      }}
                      data-testid="featured-story-quote"
                    />
                  </div>
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Section>
  )
}
