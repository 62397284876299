import { useMemoizedContentGetter } from 'utils/contentful'
import { PaperCarousel } from 'components/Carousels/PaperCarousel'
import { object, array, string } from 'yup'
import { richTextSchema, carouselItemSchema } from 'constants/validation-types'

let contentSchema = object({
  title: object({
    textContent: richTextSchema,
  }),
  theme: object({
    theme: array(string()),
  }).default({ theme: ['light-blue'] }),
  carousel: object({
    carouselItems: carouselItemSchema,
  }),
})

function MoreResourcesCarousel({ content }) {
  let memoizedContent = useMemoizedContentGetter(content, ['title', 'carousel', 'theme'])
  if (!contentSchema.isValidSync(memoizedContent)) {
    console.warn(
      'MoreResourcesCarousel - INVALID CONTENT RESPONSE',
      JSON.stringify(memoizedContent)
    )
    return null
  }

  const {
    title,
    theme,
    carousel: { carouselItems },
  } = memoizedContent

  return <PaperCarousel title={title} carouselItems={carouselItems} theme={theme} idPrefix="home" />
}

export { MoreResourcesCarousel }
