import React from 'react'
import { Typography } from 'components/Contentful'
import styles from './TitleDivider.module.scss'

function TitleDivider({
  title,
  subtitle,
  className,
  variants,
  titleTag = 'h2',
  subtitleTag = 'h3',
}) {
  return (
    <div className={`${styles['title-divider']} ${className ? className : ''}`}>
      {title && (
        <Typography
          data-testid="title-divider-title"
          className={styles['title']}
          content={title.textContent}
          variant={variants?.title}
          sx={{ margin: 0 }}
          fontWeight={variants?.titleFontWeight}
          component={titleTag}
        />
      )}
      {subtitle && (
        <Typography
          data-testid="title-divider-subtitle"
          className={styles['subtitle']}
          content={subtitle.textContent}
          variant={variants?.subtitle}
          fontWeight={variants?.subtitleFontWeight}
          component={subtitleTag}
        />
      )}
    </div>
  )
}

export { TitleDivider }
