import { useMemoizedContentGetter } from 'utils/contentful'
import { Typography, MediaImageStandard } from 'components/Contentful'
import { useViewportLargerThan, BREAKPOINTS } from 'utils/mui'
import { object } from 'yup'
import { Grid } from '@ffn/sunbeam'
import Section from 'components/Section'
import styles from './ImageGridSection.module.scss'
import { richTextSchema, imageFieldSchema } from 'constants/validation-types'

const contentSchema = object({
  preHeading: object({
    textContent: richTextSchema,
  }),
  heading: object({
    textContent: richTextSchema,
  }),
  subHeading: object({
    textContent: richTextSchema,
  }),
  mainCopy: object({
    textContent: richTextSchema,
  }),
  gridImage: object({
    mediaContent: imageFieldSchema,
    mobileMediaContent: imageFieldSchema,
  }),
})

export const ImageGridSection = ({ content }) => {
  const isLargeViewport = useViewportLargerThan(BREAKPOINTS.lg)
  const memoizedContent = useMemoizedContentGetter(content, [
    'preHeading',
    'heading',
    'subHeading',
    'mainCopy',
    'gridImage',
  ])

  if (!contentSchema.isValidSync(memoizedContent)) {
    console.warn('ImageGridSection - INVALID CONTENT RESPONSE', JSON.stringify(memoizedContent))
    return null
  }

  const { preHeading, heading, subHeading, mainCopy, gridImage } = memoizedContent

  return (
    <Section contain={false} childContainerClassName={styles['image-grid-section']}>
      <Grid
        container
        direction={isLargeViewport ? 'row' : 'column'}
        spacing={{ xxs: 0, lg: 4 }}
        textAlign="center"
        justifyContent="flex-end"
        data-testid="member-stories-image-grid-container"
      >
        <Grid item xxs={12} lg={6}>
          <div className={styles['copy-section-container']}>
            {preHeading?.textContent && (
              <div className={styles['pre-heading']}>
                <Typography
                  data-testid="member-stories-image-grid-pre-heading"
                  content={preHeading.textContent}
                  variant="displayS20"
                  fontWeight="medium"
                  component="p"
                />
              </div>
            )}
            {heading?.textContent && (
              <div className={styles['heading']}>
                <Typography
                  data-testid="member-stories-image-grid-heading"
                  content={heading?.textContent}
                  variant={!isLargeViewport ? 'displayM10' : 'displayM30'}
                  fontWeight="bold"
                  component="h3"
                />
              </div>
            )}
            {subHeading?.textContent && (
              <div className={styles['sub-heading']}>
                <Typography
                  data-testid="member-stories-image-grid-sub-heading"
                  content={subHeading?.textContent}
                  variant="displayS20"
                  fontWeight="medium"
                  component="p"
                />
              </div>
            )}
            {mainCopy?.textContent && (
              <div className={styles['main-copy']}>
                <Typography
                  data-testid="member-stories-image-grid-main-copy"
                  content={mainCopy?.textContent}
                  variant="bodyS30"
                  fontWeight="normal"
                  component="p"
                  lineHeight="170%" // TODO alert and update Sunbeam
                />
              </div>
            )}
          </div>
        </Grid>

        {isLargeViewport && gridImage?.mediaContent ? (
          <Grid item container justifyContent="flex-end" xxs={5}>
            <div className={styles['grid-image']}>
              <MediaImageStandard content={gridImage?.mediaContent} />
            </div>
          </Grid>
        ) : null}

        {!isLargeViewport && gridImage?.mobileMediaContent ? (
          <Grid item container xxs={12}>
            <div className={styles['grid-image']}>
              <MediaImageStandard content={gridImage?.mobileMediaContent} />
            </div>
          </Grid>
        ) : null}
      </Grid>
    </Section>
  )
}
