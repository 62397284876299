import { useEffect, useState, useRef } from 'react'
import { Grid, Paper, Slider } from '@ffn/sunbeam'
import { Typography, MediaImage, LinkButton } from 'components/Contentful'
import { useViewportLargerThan, BREAKPOINTS } from 'utils/mui'
import styles from './HeroForm.module.scss'

function SliderTool({
  stats,
  ctaText,
  ctaLinkBase,
  slider,
  isLarge,
  sliderStatsTitle,
  sliderQuestion,
  sliderQueryParam,
}) {
  const [sliderValue, setSliderValue] = useState(0)
  const [ctaHref, setCtaHref] = useState('')
  const dollarUSLocale = Intl.NumberFormat('en-US')

  useEffect(() => {
    setCtaHref(`${ctaLinkBase}&${sliderQueryParam}=${sliderValue}`)
  }, [sliderValue, ctaLinkBase, sliderQueryParam])

  useEffect(() => {
    setSliderValue(slider.max / 2)
  }, [slider])

  function handleChange(e) {
    setSliderValue(e.target.value)
  }

  return (
    <Paper className={styles['slider-tool-paper']} data-testid="product-hero-slider-container">
      <Grid container>
        <Grid item xs={12}>
          <Typography
            content={sliderQuestion}
            variant={isLarge ? 'displayS10' : 'displayM10'}
            fontWeight="bold"
            className={styles['slider-tool-title']}
            data-testid="product-hero-slider-title"
          />
        </Grid>
        <Grid item xs={12} xl={7}>
          <div className={styles['slider-tool-wrapper']}>
            <Typography
              content={`$${dollarUSLocale.format(sliderValue)}${
                sliderValue === slider.max && slider.maxPlus ? '+' : ''
              }`}
              variant="displayS30"
              fontWeight="bold"
              className={styles['slider-tool-value']}
              data-testid="product-hero-dollar-value"
            />
            <Slider
              aria-label="dollar-amount-selection-slider"
              className={styles['slider-tool-slider']}
              classes={{
                mark: styles['marks'],
              }}
              data-testid="product-hero-slider-input"
              value={sliderValue}
              step={slider.step}
              min={slider.min}
              max={slider.max}
              marks={[
                {
                  value: slider.min,
                  label: (
                    <Typography
                      content={`$${dollarUSLocale.format(slider.min)}`}
                      variant="displayXS30"
                      fontWeight="medium"
                      className={styles['slider-tool-min-mark']}
                      data-testid="product-hero-slider-min"
                    />
                  ),
                },
                {
                  value: slider.max,
                  label: (
                    <Typography
                      content={`$${dollarUSLocale.format(slider.max)}${slider.maxPlus ? '+' : ''}`}
                      variant="displayXS30"
                      fontWeight="medium"
                      className={styles['slider-tool-max-mark']}
                      data-testid="product-hero-slider-max"
                    />
                  ),
                },
              ]}
              onChange={handleChange}
            />
          </div>
        </Grid>
        <Grid item xs={12} xl={5}>
          <LinkButton
            track={{
              nav_link_section: 'Hero Form',
              click_type: 'Click Link',
              click_position: '0',
              click_id: ctaText,
              hero_card_text: ctaText,
              form_ss_amount: 1000,
              track_event: 'internal_campaign_click',
            }}
            content={{
              linkHref: ctaHref,
              linkText: ctaText,
            }}
            typographicOptions={{ variant: 'displayS10', fontWeight: 'bold' }}
            color="primary"
            variant="contained"
            className={styles['slider-tool-cta']}
            data-testid="product-hero-slider-cta"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            content={sliderStatsTitle}
            variant="bodyS20"
            fontWeight="regular"
            className={styles['slider-tool-stats-title']}
            data-testid="product-hero-slider-stats-title"
          />
        </Grid>
        <Grid item xs={12}>
          {stats.map((st) => (
            <div className={styles['slider-tool-stats']} key={st.group}>
              <Typography
                content={st.figure}
                variant={isLarge ? 'displayXS30' : 'displayS10'}
                fontWeight="bold"
                data-testid="product-hero-slider-stat-value"
              />
              <Typography
                content={st.group}
                variant="bodyS20"
                fontWeight="regular"
                data-testid="product-hero-slider-stat-desc"
              />
            </div>
          ))}
        </Grid>
      </Grid>
    </Paper>
  )
}

function HeroForm({
  title,
  subtitle,
  breadcrumb,
  image,
  mobileImage,
  alt,
  sliderStatsTitle,
  sliderQuestion,
  sliderProps,
}) {
  const isLarge = useViewportLargerThan(BREAKPOINTS.lg)
  const currentSection = useRef(null)

  if (isLarge) {
    return (
      <Grid container data-testid="desktop-product-hero-container">
        <Grid className={styles['product-hero-form-container']} item xxs={7}>
          <Typography
            content={breadcrumb}
            className={styles['breadcrumb-text']}
            data-testid="product-hero-breadcrumb-text"
            variant="displayS11CTA"
            fontWeight="medium"
          />
          <Typography
            content={title}
            variant="displayL10"
            data-testid="product-hero-title-text"
            fontWeight="bold"
            className={styles['title-text']}
          />
          <Typography content={subtitle} variant="displayS10" />
          <SliderTool
            {...sliderProps}
            isLarge
            sliderStatsTitle={sliderStatsTitle}
            sliderQuestion={sliderQuestion}
          />
        </Grid>
        <Grid item xxs={5} alignSelf="flex-start">
          <MediaImage
            content={image}
            alt={alt}
            layout="responsive"
            objectFit="cover"
            objectPosition="center"
            width="561px"
            height="687px"
            className={styles['desktop-hero-image']}
            data-testid="product-hero-image"
            priority={true}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <div
        className={styles['mobile-hero-image']}
        data-testid="mobile-product-hero-container"
        ref={currentSection}
      >
        <MediaImage
          content={mobileImage}
          layout="fill"
          objectFit="cover"
          objectPosition="top"
          width
          height
          priority={true}
        />
        <div className={styles['mobile-hero-content']}>
          <Typography
            content={breadcrumb}
            className={styles['breadcrumb-text']}
            data-testid="product-hero-breadcrumb-text"
            variant="displayS10"
            fontWeight="bold"
          />
          <Typography
            content={title}
            variant="displayM10"
            fontWeight="bold"
            className={styles['title-text']}
            data-testid="product-hero-title-text"
          />
        </div>
      </div>
      <SliderTool
        {...sliderProps}
        isLarge={false}
        sliderStatsTitle={sliderStatsTitle}
        sliderQuestion={sliderQuestion}
      />
      <div className={styles['mobile-blue-section']}>
        <Typography
          content={subtitle}
          variant="displayM10"
          fontWeight="bold"
          className={styles['mobile-subtitle-text']}
          data-testid="product-hero-blue-text"
        />
      </div>
    </>
  )
}

export { HeroForm }
