import { CardPressSection } from 'components/Cards/CardPressSection'
import { Box } from '@ffn/sunbeam'
import { useMemoizedContentGetter } from 'utils/contentful'
import { BREAKPOINTS, useViewportSmallerThan } from 'utils/mui'
import { BLOCKS } from '@contentful/rich-text-types'
import { Typography } from 'components/Contentful'
import styles from './PressKitDownload.module.scss'
import { Breadcrumb } from 'components/Breadcrumb/Breadcrumb'

export const PressKitDownload = ({ content }) => {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)

  const listSections = ['breadcrumb', 'title', 'subtitle']
  const { breadcrumb, title, subtitle } = useMemoizedContentGetter(content, listSections)
  const listItemsDownload = content?.fields?.sectionContents?.filter(
    (i) => i?.sys?.contentType?.sys?.id === 'downloableAttachment'
  )
  const titleText = title?.textContent?.content[0]?.content[0]?.value
  const subtitleText = subtitle?.textContent?.content[0]?.content[0]?.value
  return (
    <Box paddingTop={3} display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <Box className={styles['container-breadcrumb']}>
        <Breadcrumb content={breadcrumb}></Breadcrumb>
      </Box>
      <Box className={styles['container-body']}>
        <Box display={'flex'} flexDirection={'column'} alignItems={isMobile ? 'center' : 'initial'}>
          <Typography
            className={styles['description-text']}
            content={titleText}
            component="h1"
            variant={'displayM10'}
            fontWeight="medium"
            styleOverride={isMobile && { [BLOCKS.HEADING_1]: { 'text-align': 'center' } }}
          />
          <Typography
            className={styles['description-info']}
            content={subtitleText}
            variantOverride={{ [BLOCKS.PARAGRAPH]: 'displayS10' }}
            fontWeight="regular"
            styleOverride={isMobile && { [BLOCKS.PARAGRAPH]: { 'text-align': 'center' } }}
          />
          <hr className={styles.separator} />
        </Box>
        <Box>
          {listItemsDownload.map((element, index) => (
            <CardPressSection key={`preess-card-${index}`} content={element} />
          ))}
        </Box>
      </Box>
    </Box>
  )
}
