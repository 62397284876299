import styles from './CardLeadership.module.scss'
import { Typography, MediaImage } from 'components/Contentful'
import { BLOCKS } from '@contentful/rich-text-types'
import { Card, CardActionArea } from '@ffn/sunbeam'

export const CardLeadership = ({ content, testIdIndex }) => {
  if (content === undefined || Object.keys(content).length === 0) return null
  const { cardTitleText, cardText, cardLink, cardImage } = content?.fields || {}
  return (
    <Card className={styles.card} data-testid={`leadership-card-${testIdIndex}`}>
      <CardActionArea
        component={'a'}
        href={cardLink?.fields?.linkHref}
        aria-label={`${cardTitleText?.content[0]?.content[0]?.value} - link opens in a new tab`}
        target="_blank"
        rel="noreferrer nofollow"
        className={styles.action}
        data-testid={`leadership-card-link-${testIdIndex}`}
      >
        <MediaImage content={cardImage} layout="fill" />
        <div className={styles.footer} data-testid="leadership-card-footer">
          <Typography
            content={cardTitleText}
            variantOverride={{ [BLOCKS.PARAGRAPH]: 'displayS10' }}
            fontWeight="bold"
            data-testid={`leadership-card-title-${testIdIndex}`}
          />
          <Typography
            content={cardText}
            variantOverride={{ [BLOCKS.PARAGRAPH]: 'displayXS20' }}
            data-testid={`leadership-card-text-${testIdIndex}`}
          />
        </div>
      </CardActionArea>
    </Card>
  )
}
