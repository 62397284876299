import styles from './HeroMedia.module.scss'
import { Box } from '@ffn/sunbeam'
import { MediaImage } from 'components/Contentful'
import { debounce as _debounce } from 'lodash-es'
import useIsSsr from 'hooks/useIsSsr'
import { useEffect, useRef, useState } from 'react'

function HeroMedia({ className, image, video, alt, children }) {
  const isSsr = useIsSsr()
  const [onLandscape, setOnLandscape] = useState(true)
  const videoRef = useRef()

  useEffect(() => {
    setOnLandscape(window.innerWidth > window.innerHeight)

    const handleResize = _debounce(
      () => {
        setOnLandscape(window.innerWidth > window.innerHeight)
      },
      250,
      { maxWait: 250 }
    )
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isSsr])

  useEffect(() => {
    videoRef.current?.load()
  }, [video])

  if (!video)
    return (
      <Box className={className}>
        <MediaImage
          className={styles['image']}
          content={image}
          alt={alt}
          layout="fill"
          objectFit="cover"
          objectPosition="left top"
        />
        <Box className={styles['hero-media-content']}>{children}</Box>
      </Box>
    )
  return (
    <Box className={className} data-on-landscape={onLandscape}>
      <video
        autoPlay
        muted
        playsInline
        loop
        id="play-video"
        className={styles['video']}
        ref={videoRef}
      >
        <source src={video} type="video/mp4" />
      </video>
      <Box className={styles['hero-media-content']}>{children}</Box>
    </Box>
  )
}

export { HeroMedia }
