import { Grid } from '@ffn/sunbeam'
import { CardProduct } from 'components/Cards/CardProduct'

function ThreeProductCard({ gridContentArray = [], isMobile = true }) {
  return (
    <>
      {gridContentArray && (
        <Grid
          container
          justifyContent={{ xxs: 'center' }}
          alignItems={{ xs: 'stretch' }}
          spacing={isMobile ? '24px' : '32px'}
          // rowSpacing={0}
          data-testid="home-product-card-section-card-grid"
          // lg={6}
        >
          {(gridContentArray.gridItems || []).map((gridContent, index) => {
            return (
              <Grid
                item
                xxs={12}
                sm={9}
                md={6}
                lg={4}
                key={`${gridContent?.sys?.id}-${index}`}
                data-testid={`home-product-card-section-card-grid-item-${index}`}
              >
                <CardProduct
                  content={gridContent}
                  isMobile={isMobile}
                  data-testid={`home-product-card-section-card-${index}`}
                />
              </Grid>
            )
          })}
        </Grid>
      )}
    </>
  )
}

export { ThreeProductCard }
