import { useMemoizedContentGetter } from 'utils/contentful'
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@ffn/sunbeam'
import Section from 'components/Section'
import { Typography, LinkButton } from 'components/Contentful'
import { useState } from 'react'
import { TitleDivider } from 'components/TitleDivider'
import { BREAKPOINTS, useViewportSmallerThan } from 'utils/mui'
import { object } from 'yup'
import { ctaButtonSchema, faqSetSchema, richTextSchema } from 'constants/validation-types'
import { ChevronDown } from 'react-feather'
import { FAQPageJsonLd } from 'next-seo'
import styles from './Faqs.module.scss'

const contentSchema = object({
  button: ctaButtonSchema,
  faqs: object({
    faqSets: faqSetSchema,
  }),
  title: object({
    textContent: richTextSchema,
  }),
})

export function Faqs({ content }) {
  const [selected, setSelected] = useState('set1')
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)

  const memoizedContent = useMemoizedContentGetter(content, ['button', 'faqs', 'title'])

  if (!contentSchema.isValidSync(memoizedContent)) {
    console.warn('FaqsSection - INVALID CONTENT RESPONSE', JSON.stringify(memoizedContent))
    return null
  }

  const { button, faqs, title } = memoizedContent
  const sets = faqs?.faqSets
  const sectionName = content?.fields?.fieldName || 'faqs'

  const makeAccordion = (answer, question, setNumber) => {
    return (
      <Accordion
        className={styles['accordion']}
        data-testid="faqs-accordion"
        expanded={selected === setNumber}
        id={`faq-${setNumber}`}
        key={setNumber}
        onChange={() => setSelected(selected === setNumber ? '' : setNumber)}
      >
        <AccordionSummary
          className={styles['accordion-summary']}
          data-testid="faqs-question"
          expandIcon={
            <ChevronDown
              className={selected === setNumber ? styles['expand-icon-selected'] : ''}
              size={isMobile ? 24 : 32}
            />
          }
        >
          <Typography
            className={styles['accordion-summary-text']}
            content={question}
            fontWeight="medium"
            variant={isMobile ? 'displayS10' : 'displayS20'}
            component="h2"
          />
        </AccordionSummary>
        <AccordionDetails data-testid="faqs-answer">
          <Typography content={answer} variant="bodyS30" component="p" />
        </AccordionDetails>
      </Accordion>
    )
  }

  const makeSeoEntity = (answer, question) => {
    return {
      questionName: question,
      acceptedAnswerText: answer,
    }
  }

  const mapFaqSets = () => {
    let entities = []
    let faqs = []

    sets.map((set) => {
      const answer = set?.fields?.answer?.content[0]?.content[0]?.value
      const question = set?.fields?.question?.content[0]?.content[0]?.value
      const setNumber = set?.fields?.fieldName
      const entity = makeSeoEntity(answer, question)
      const accordion = makeAccordion(answer, question, setNumber)

      entities.push(entity)
      faqs.push(accordion)
    })

    return (
      <>
        <FAQPageJsonLd mainEntity={entities} />
        {faqs}
      </>
    )
  }

  const variant = {
    title: isMobile ? 'displayS30' : 'displayM20',
    titleFontWeight: 'bold',
    subtitle: isMobile ? 'bodyS30' : 'bodyM10',
    subtitleFontWeight: 'regular',
  }

  return (
    <Section
      className={styles['faq-section']}
      data-testid={`${sectionName}-section`}
      id="faq-section"
    >
      <Grid
        alignItems="center"
        columnSpacing={{ xs: 1, lg: 3 }}
        container
        data-testid="faqs-grid-container"
        justifyContent="center"
      >
        <Grid data-testid="faqs-grid-item-title" item xs={11}>
          <TitleDivider
            title={title}
            isMobile={isMobile}
            data-testid="faqs-section-title-area"
            className={styles['title']}
            titleTag="h3"
            variants={variant}
          />
        </Grid>
        <Grid data-testid="faqs-grid-item-accordion" item xs={11} lg={9}>
          {sets && mapFaqSets()}
        </Grid>
        <Grid data-testid="faqs-grid-item-button" item pt={8} xs={11}>
          <Grid container justifyContent="center">
            {button && (
              <LinkButton
                track={{
                  nav_link_section: 'Closer Statement',
                  click_type: 'Click Link',
                  click_position: '0',
                  click_id: 'faq button',
                  form_ss_amount: 1000,
                  track_event: 'internal_campaign_click',
                }}
                className={styles['button']}
                content={button}
                data-testid="faqs-button"
                typographicOptions={{
                  fontWeight: 'bold',
                  'data-testid': 'faqs-button-text',
                }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Section>
  )
}
