import { useEffect, useRef, useReducer } from 'react'
import { Grid, CircularProgress } from '@ffn/sunbeam'
import { MadLibResults } from './MadLibResults'
import { MadLibForm } from './MadLibForm'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import styles from './MadLib.module.scss'
import classNames from 'classnames'

const MAD_LIB_FORM = 'MAD_LIB_FORM'
const MAD_LIB_PROGRESS = 'MAD_LIB_PROGRESS'
const MAD_LIB_RESULTS = 'MAD_LIB_RESULTS'
const MAD_LIB_PROGRESS_DURATION = 1000

const madLibReducer = (state, action) => {
  switch (action.type) {
    case 'INIT_STATE':
      return action.payload
    case 'SET_LINE_ONE_VALUE':
      return { ...state, lineOneValue: action.payload }
    case 'SET_LINE_TWO_VALUE':
      return { ...state, lineTwoValue: action.payload }
    case 'SET_STEP':
      return { ...state, step: action.payload }
    case 'SET_MAD_LIB_CONTENT':
      return { ...state, madLibContent: action.payload }
    case 'SET_MAD_LIB_STEP':
      return { ...state, madLibStep: action.payload }
    case 'SET_MAD_LIB_Progress_Out':
      return { ...state, madLibProgressOut: action.payload }
    default:
      return state
  }
}

function MadLib({
  button,
  defaultResult,
  lineOneOptions,
  lineTwoOptions,
  resetLinkText,
  results,
  resultsMap,
  resultsSubtitle,
  title,
}) {
  const [
    { lineOneValue, lineTwoValue, step, madLibContent, madLibStep, madLibProgressOut },
    dispatch,
  ] = useReducer(madLibReducer, {
    lineOneValue: '',
    lineTwoValue: '',
    step: MAD_LIB_FORM,
    madLibContent: null,
    madLibStep: MAD_LIB_FORM,
    madLibProgressOut: false,
  })

  const progressTimeoutRef = useRef(null)
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)

  const onLineOneChange = (val) => dispatch({ type: 'SET_LINE_ONE_VALUE', payload: val })
  const onLineTwoChange = (val) => dispatch({ type: 'SET_LINE_TWO_VALUE', payload: val })
  const onNextClick = () => dispatch({ type: 'SET_STEP', payload: MAD_LIB_PROGRESS })
  const onResetForm = () => dispatch({ type: 'SET_STEP', payload: MAD_LIB_FORM })

  // const [madLibProgressOut, setMadLibProgressOut] = useState(false)

  useEffect(() => {
    if (lineOneOptions.dropDownOptions.length > 0 && lineTwoOptions.dropDownOptions.length > 0) {
      dispatch({
        type: 'INIT_STATE',
        payload: {
          lineOneValue: lineOneOptions.dropDownOptions[0].value,
          lineTwoValue: lineTwoOptions.dropDownOptions[0].value,
          step: MAD_LIB_FORM,
        },
      })
    }
  }, [lineOneOptions.dropDownOptions, lineTwoOptions.dropDownOptions])

  useEffect(() => {
    // The purpose of this effect is to fake the progress state, as if we're making a network call
    if (step === MAD_LIB_PROGRESS) {
      progressTimeoutRef.current = setTimeout(
        () => dispatch({ type: 'SET_STEP', payload: MAD_LIB_RESULTS }),
        MAD_LIB_PROGRESS_DURATION
      )
    } else {
      clearTimeout(progressTimeoutRef.current)
    }
  }, [step])

  useEffect(() => {
    const form = (
      <MadLibForm
        button={button}
        isMobile={isMobile}
        lineOneOptions={lineOneOptions}
        lineOneValue={lineOneValue}
        lineTwoOptions={lineTwoOptions}
        lineTwoValue={lineTwoValue}
        onLineOneChange={onLineOneChange}
        onLineTwoChange={onLineTwoChange}
        onNextClick={onNextClick}
        title={title}
      />
    )
    switch (step) {
      case MAD_LIB_RESULTS:
        dispatch({
          type: 'SET_MAD_LIB_CONTENT',
          payload: (
            <MadLibResults
              defaultResult={defaultResult}
              isMobile={isMobile}
              lineOneOptions={lineOneOptions}
              lineTwoOptions={lineTwoOptions}
              lineOneValue={lineOneValue}
              lineTwoValue={lineTwoValue}
              onResetClick={onResetForm}
              resetLinkText={resetLinkText}
              results={results}
              resultsMap={resultsMap}
              resultsSubtitle={resultsSubtitle}
            />
          ),
        })
        break
      case MAD_LIB_PROGRESS:
        dispatch({
          type: 'SET_MAD_LIB_CONTENT',
          payload: (
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              className={styles['mad-lib-progress-spinner']}
            >
              <CircularProgress />
            </Grid>
          ),
        })
        break
      default:
        if (madLibStep === MAD_LIB_RESULTS) {
          dispatch({ type: 'SET_MAD_LIB_Progress_Out', payload: true })
          dispatch({ type: 'SET_MAD_LIB_CONTENT', payload: form })
          setTimeout(() => {
            dispatch({ type: 'SET_MAD_LIB_Progress_Out', payload: false })
          }, 1100)
        } else dispatch({ type: 'SET_MAD_LIB_CONTENT', payload: form })
        break
    }
    dispatch({ type: 'SET_MAD_LIB_STEP', payload: step })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    button,
    defaultResult,
    isMobile,
    lineOneOptions,
    lineOneValue,
    lineTwoOptions,
    lineTwoValue,
    resetLinkText,
    results,
    resultsMap,
    resultsSubtitle,
    step,
    title,
  ])
  return (
    <div className={styles['mad-lib-container']} data-is-progress-out={madLibProgressOut}>
      <div
        className={classNames([
          styles['mad-lib-layout'],
          step !== MAD_LIB_PROGRESS && styles['mad-lib-layout-expanded'],
        ])}
      >
        {madLibContent}
      </div>
    </div>
  )
}

export { MadLib }
