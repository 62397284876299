import styles from './ValueProps.module.scss'
import { Grid, Box } from '@ffn/sunbeam'
import Section from 'components/Section'
import { CardValueProp } from 'components/Cards'
import { TitleDivider } from 'components/TitleDivider'
import { BLOCKS } from '@contentful/rich-text-types'
import { LinkButton } from 'components/Contentful'
import { BREAKPOINTS, useViewportSmallerThan } from 'utils/mui'
import { useMemoizedContentGetter } from 'utils/contentful'
import useTheme from 'hooks/useTheme'

function ValueProps({ content }) {
  const { title, subtitle, grid, theme, linkButton } = useMemoizedContentGetter(content, [
    'title',
    'subtitle',
    'grid',
    'theme',
    'linkButton',
  ])

  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)

  const fieldName = content.fields.fieldName

  const achieveTheme = useTheme()
  const backgroundColor = theme?.theme[0]
    ? achieveTheme?.sb?.colors?.primary?.highlightBlue
    : achieveTheme?.sb?.colors?.neutral?.white
  const variant = {
    title: isMobile ? 'displayS30' : 'displayM20',
    titleFontWeight: 'bold',
    subtitle: isMobile ? 'bodyS30' : 'bodyM10',
    subtitleFontWeight: 'regular',
  }

  const breakline = ['aboutUsCulture', 'valueSeparatorProps'].includes(fieldName)

  return (
    <Section
      backgroundColor={backgroundColor}
      className={!breakline ? styles['section'] : styles['section-breakline']}
      // contain={false}
    >
      <Grid
        container
        justifyContent="center"
        data-testid="value-prop-grid-container"
        className={styles['container-grid']}
      >
        <div className={styles['grid-wrapper']}>
          <TitleDivider
            data-testid="value-prop-title-divider"
            className={styles['title-divider-wrapper']}
            title={title}
            subtitle={subtitle}
            isMobile={isMobile}
            variants={variant}
            component="h2"
          />
        </div>
        <Grid
          item
          sm={10}
          md={8}
          lg={12}
          data-testid="value-prop-grid-card-container"
          className={[styles['card-grid'], breakline && styles['breakline']]}
        >
          {grid?.gridItems.map((item, idx) => {
            const card = (
              <Box
                data-testid={`value-prop-grid-card-${idx}`}
                className={styles['value-prop-grid-card']}
                key={`card-${idx}`}
              >
                <CardValueProp content={item} isMobile={isMobile} breakline={breakline} />
              </Box>
            )
            const separator = (
              <Box
                data-testid={`value-prop-grid-separator-${idx}`}
                className={styles['value-prop-grid-separator']}
                key={`separator-${idx}`}
              >
                <div></div>
              </Box>
            )
            if (idx > 0) {
              return [separator, card]
            }
            return card
          })}
        </Grid>
        {linkButton && (
          <div className={styles['button-wrapper']}>
            <LinkButton
              className={styles['button']}
              content={linkButton}
              typographicOptions={{
                variantOverride: { [BLOCKS.PARAGRAPH]: isMobile ? 'displayXS30' : 'displayS10' },
                fontWeight: 'bold',
                'data-testid': 'value-prop-button-text',
                className: styles['typography'],
              }}
              color="primary"
              data-testid="value-prop-button"
              track={{
                nav_link_section: 'Value Props',
                click_type: 'Click Link',
                click_position: '0',
                click_id: 'Get Started',
                form_ss_amount: 1000,
                track_event: 'internal_campaign_click',
              }}
            />
          </div>
        )}
      </Grid>
    </Section>
  )
}

export { ValueProps }
