import { Typography, MediaImageStandard } from 'components/Contentful'
import { Grid } from '@ffn/sunbeam'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import { useRouter } from 'next/router'
import { formatPublishDate } from 'utils/conversions'
import { STORY_TYPES } from 'constants/storyTypes'
import { removePageFromQuery } from 'utils/next/routes'

import styles from 'components/Cards/CardPress/CardPress.module.scss'

function CardPress({ article }) {
  const { articleMainPhoto, storyType, title, publishDate, slug, externalLink } = article

  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const router = useRouter()

  const removeQueryAndReturnLink = () => {
    return (
      <div
        onClick={() => {
          if (router.query?.page) delete router.query.page
          router.replace(router, null, { shallow: true })
        }}
      >
        <AchieveLink
          href={
            storyType === STORY_TYPES.IN_THE_NEWS && externalLink
              ? `${externalLink}`
              : `${router.pathname}/${slug}${
                  Object.keys(router.query).length === 0
                    ? ''
                    : `?${removePageFromQuery(router.query)}`
                }`
          }
          className={styles['link']}
          target={storyType === STORY_TYPES.IN_THE_NEWS ? '_blank' : '_self'}
          rel={storyType === STORY_TYPES.IN_THE_NEWS ? 'noreferrer nofollow' : ''}
        >
          <Typography content={'Read more'} fontWeight="bold" variant="displayXS30" />
        </AchieveLink>
      </div>
    )
  }

  const ClickableWrapper = ({ children }) => {
    return (
      /* Disable eslint complaint since '_blank' is conditionally applied */
      /* eslint-disable-next-line react/jsx-no-target-blank */
      <a
        className={styles['anchor-links']}
        target={storyType === STORY_TYPES.IN_THE_NEWS ? '_blank' : '_self'}
        rel={storyType === STORY_TYPES.IN_THE_NEWS ? 'noreferrer nofollow' : ''}
        href={
          storyType === STORY_TYPES.IN_THE_NEWS && externalLink
            ? `${externalLink}`
            : `${router.pathname}/${slug}${
                Object.keys(router.query).length === 0
                  ? ''
                  : `?${removePageFromQuery(router.query)}`
              }`
        }
      >
        {children}
      </a>
    )
  }

  return (
    <Grid className={styles['card-container']}>
      {articleMainPhoto && (
        <ClickableWrapper>
          <Grid className={styles['image-container']}>
            <MediaImageStandard
              content={articleMainPhoto}
              layout="fill"
              height={isMobile ? 117 : 215}
              width={isMobile ? 312 : 390}
            />
          </Grid>
        </ClickableWrapper>
      )}
      <Grid className={styles['card-content']}>
        {storyType && (
          <Typography
            content={storyType}
            variant="displayXS30"
            className={styles['card-story-type']}
            data-testid={`home-product-cards-section-card-story-type`}
          />
        )}
        {title && (
          <ClickableWrapper>
            <Typography
              content={title}
              variant={isMobile ? 'displayS10' : 'displayS20'}
              component="h3"
              className={styles['card-title']}
              fontWeight="bold"
              data-testid={`home-product-cards-section-card-title`}
            />
          </ClickableWrapper>
        )}
        {publishDate && (
          <Typography
            content={formatPublishDate(publishDate)}
            variant="bodyS30"
            className={styles['card-date']}
            fontWeight="regular"
            data-testid={`home-product-cards-section-card-text`}
          />
        )}
        <div className={styles['link-container']}>{slug && removeQueryAndReturnLink()}</div>
      </Grid>
    </Grid>
  )
}

export { CardPress }
