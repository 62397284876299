import { useEffect, useRef, useState } from 'react'
import useIsSsr from 'hooks/useIsSsr'
import { Slide } from '@material-ui/core'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'

function SlideOnVisible({
  duration = 1600,
  timeout = 700,
  scrollOnMobile = true,
  direction = 'up',
  children,
  easing,
}) {
  const isSsr = useIsSsr()
  const [isVisible, setIsVisible] = useState(false)
  const currentRef = useRef()
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  useEffect(() => {
    if (isSsr) {
      // no window to measure during SSR
      return
    }
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSsr])
  const scrollHandler = () => {
    const lineTop = window.pageYOffset
    const lineBottom = window.pageYOffset + window.innerHeight
    const borderTop = currentRef.current.offsetTop
    if (lineBottom >= borderTop && lineTop <= borderTop) {
      setTimeout(() => setIsVisible(true), timeout)
    }
  }
  if (!scrollOnMobile && isMobile) {
    return <div ref={currentRef}>{children}</div>
  }
  return (
    <div ref={currentRef}>
      <Slide
        direction={direction}
        in={isVisible}
        mountOnEnter
        container={currentRef}
        timeout={duration}
        easing={easing}
      >
        {children}
      </Slide>
    </div>
  )
}
export default SlideOnVisible
