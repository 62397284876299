import React, { useEffect, useState } from 'react'
import Section from 'components/Section'

import { Typography } from 'components/Contentful/Typography'
import { Grid } from '@ffn/sunbeam'
import { Filter } from 'components/Filter'
import { CardPress } from 'components/Cards/CardPress'

import { useMemoizedContentGetter } from 'utils/contentful'
import { object } from 'yup'
import { richTextSchema } from 'constants/validation-types'

import styles from './PaginatedStoryGrid.module.scss'
import useViewPortSize from 'hooks/useViewportSize'

import { Pagination } from '@ffn/sunbeam'
import { useRouter } from 'next/router'
import { AchieveLink } from 'components/AchieveLink'

let contentSchema = object({
  title: object({
    textContent: richTextSchema,
  }),
})

function PaginatedStoryGrid({ content }) {
  const router = useRouter()

  const variant = useViewPortSize()

  let memoizedContent = useMemoizedContentGetter(content, ['title', 'filter', 'config'])

  let [articles, setArticles] = useState([])
  let [filterBy, setFilterBy] = useState('*')
  let [page, setPage] = useState(router.query ? router.query.page : 1)
  let [titleId, setTitleId] = useState('press_story_grid')

  useEffect(() => {
    setPage(1)
    setTitleId(`${slugifyTitleForId(title.textContent)}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let body = {
      content_type: config.jsonContent.longFormContentType,
      pageLimit: config.jsonContent.pageLimit,
      page,
    }

    if (filterBy !== '*') {
      body.filter = filterBy
    }

    fetch('/api/contentful/fetch', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
      .then((res) => {
        return res.json()
      })
      .then((contentfulJson) => {
        setArticles(contentfulJson)
      })
      .catch(() => {
        setArticles()
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filterBy])

  if (!contentSchema.isValidSync(memoizedContent)) {
    console.warn(
      'MoreResourcesCarousel - INVALID CONTENT RESPONSE',
      JSON.stringify(memoizedContent)
    )
    return null
  }

  let { title, filter, config } = memoizedContent

  const strictTypography = {
    variant: title[`variant_${variant.breakpointLetterSize}`],
    weight: title[`weight_${variant.breakpointLetterSize}`],
  }

  const handleChangePage = (event, page) => {
    setPage(page)

    router.push({ query: { ...router.query, page } }, null, { shallow: true, scroll: false })
  }

  const handleChangeFilter = (filter) => {
    setFilterBy(filter)
  }

  const slugifyTitleForId = (title) => {
    try {
      return title?.content[0]?.content[0]?.value.toLowerCase().split(' ').join('_')
    } catch (e) {
      return 'press-story-grid-title'
    }
  }

  return (
    <>
      <Section className={styles['paginated-story-grid-section']} id="press_grid">
        <Typography
          data-testid="press-grid-title"
          id={`${slugifyTitleForId(title.textContent)}`}
          className={styles['title']}
          content={title.textContent}
          variant={strictTypography.variant}
          sx={{ margin: 0 }}
          fontWeight={strictTypography.weight}
          component={title.component}
        />
        <Grid className={styles['filter']}>
          <Filter
            data-testid="press-grid-filter"
            setValue={handleChangeFilter}
            jsonContent={filter.jsonContent}
            name={filter.name}
          />
        </Grid>

        {articles.articles && (
          <>
            <Grid container className={styles['news-card-grid']}>
              <Grid container spacing={3}>
                {articles.articles.map((article, idx) => {
                  return (
                    <Grid item xs={12} md={6} xl={4} key={idx}>
                      <CardPress data-testid="press-grid-card" key={idx} article={article.fields} />
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
            <Grid item className={styles['pagination']}>
              <AchieveLink href={`#${titleId}`} withNextLink>
                <Pagination
                  color="primary"
                  className={styles['pagination-items']}
                  data-testid="press-grid-pagination"
                  count={Math.ceil(
                    parseInt(articles.total) / parseInt(config.jsonContent.pageLimit)
                  )}
                  onChange={handleChangePage}
                  page={parseInt(page)}
                />
              </AchieveLink>
            </Grid>
          </>
        )}
      </Section>
    </>
  )
}

export { PaginatedStoryGrid }
