import { Grid } from '@ffn/sunbeam'
import { CardRecommended } from 'components/Cards'
import styles from './MadLib.module.scss'

function MadLibResultsCards({ defaultResult, recommended, mobileExpanded, results }) {
  if ((!Array.isArray(results) || !results.length) && !defaultResult) {
    console.error('No Mad Lib results data provided.')
    return null
  }

  const orderedResults = recommended
    ? getOrderedMadLibResults(results, recommended)
    : [{ content: defaultResult, order: { xs: 1, lg: 2 } }]

  return (
    <Grid
      container
      style={{ width: 'auto' }}
      gap={{ lg: '32px' }}
      flexWrap={{ xs: 'wrap', lg: 'nowrap' }}
      alignItems="center"
      data-testid="mad-lib-recommended-items"
    >
      {orderedResults.map(({ content, order }, index) => (
        <Grid
          item
          xs={12}
          lg={4}
          key={`${content?.sys?.id}-${index}`}
          data-mobile-hidden={index > 0 && !mobileExpanded}
          aria-hidden={index > 0 && !mobileExpanded}
          data-testid={`mad-lib-results-card-item-${index}`}
          className={styles['mad-lib-results-grid-item']}
          order={order}
        >
          <CardRecommended
            recommended={index === 0}
            content={content}
            className={styles['mad-lib-results-card']}
            orderLarge={order.lg}
          />
        </Grid>
      ))}
    </Grid>
  )
}

function getOrderedMadLibResults(results, recommended) {
  // Organize results keyed by field name for easier referencing
  let resultsByFieldName = {}
  results.forEach((result) => {
    resultsByFieldName[result?.fields?.fieldName] = result
  })

  // Single item Array of the recommended result which will be combined with the rest of the results
  let recommendedResult = [
    {
      content: resultsByFieldName[recommended],
      order: { xs: 1, lg: 2 },
    },
  ]

  // Go through the non-recommended results assign responsive grid order
  let otherResults = []
  Object.keys(resultsByFieldName)
    .filter((fieldName) => fieldName !== recommended)
    .forEach((fieldName, index) => {
      // For small single column layout, set the order to skip '1' since that is assigned to the
      // recommended result.
      let defaultOrderSmall = index + 1 === 1 ? 2 : index + 1
      // For the three card layout, set the order to skip '2' since that is assigned to the
      // recommended result.
      let defaultOrderLarge = index + 1 > 1 ? index + 2 : index + 1

      otherResults.push({
        content: resultsByFieldName[fieldName],
        order: { xs: defaultOrderSmall, lg: defaultOrderLarge },
      })
    })
  return recommendedResult.concat(otherResults)
}

export { MadLibResultsCards }
