import { useMemoizedContentGetter } from 'utils/contentful/useMemoizedContentGetter'
import Section from 'components/Section'
import styles from './ProductCards.module.scss'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import { TitleDivider } from 'components/TitleDivider'
import { ThreeProductCard } from 'components/ThreeProductCard'
import useTheme from 'hooks/useTheme'

function ProductCards({ content }) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const theme = useTheme()
  const { title, subtitle, grid } = useMemoizedContentGetter(content, ['title', 'subtitle', 'grid'])
  const variant = {
    title: isMobile ? 'displayS30' : 'displayM20',
    titleFontWeight: 'bold',
    subtitle: isMobile ? 'bodyS30' : 'bodyM10',
    subtitleFontWeight: 'regular',
  }

  return (
    <Section
      backgroundColor={theme?.sb?.colors?.primary?.highlightBlue}
      className={styles['section']}
      data-testid="product-card-section"
    >
      <TitleDivider
        title={title}
        subtitle={subtitle}
        isMobile={isMobile}
        data-testid="product-card-section-title-area"
        variants={variant}
      />
      {grid && <ThreeProductCard gridContentArray={grid} isMobile={isMobile} />}
    </Section>
  )
}

export { ProductCards }
