import { useMemoizedContentGetter } from 'utils/contentful'
import { MediaImage, Typography } from 'components/Contentful'
import useTheme from 'hooks/useTheme'
import { Paper } from '@mui/material'
import Section from 'components/Section'
import { Grid } from '@ffn/sunbeam'
import { BLOCKS } from '@contentful/rich-text-types'
import { useViewportLargerThan, BREAKPOINTS } from 'utils/mui'
import styles from './AboutUsHero.module.scss'

export const AboutUsHero = ({ content }) => {
  const { aboutUsHeroTitle, aboutUsHeroSubTitle, aboutUsHeroImage } = useMemoizedContentGetter(
    content,
    ['aboutUsHeroTitle', 'aboutUsHeroSubTitle', 'aboutUsHeroImage']
  )

  const theme = useTheme()
  const isDesktop = useViewportLargerThan(BREAKPOINTS.lg)

  const mediaImageContent = isDesktop
    ? aboutUsHeroImage?.mediaContent
    : aboutUsHeroImage?.mobileMediaContent || aboutUsHeroImage?.mediaContent

  return (
    <Section
      data-testid="about-us-hero-section"
      disableGutters={false}
      className={styles['section']}
      childContainerClassName={styles['about-us-hero-grid-container']}
    >
      <Grid container justifyContent="center">
        <Paper elevation={10} className={`${styles['paper']}`}>
          <div className={styles['block-img']} data-testid="about-us-hero-background">
            <MediaImage
              content={mediaImageContent}
              layout="fill"
              objectFit="cover"
              objectPosition="top left"
              priority={true}
              quality={isDesktop ? 75 : 100}
            />
            <div className={styles['content']}>
              <Typography
                content={aboutUsHeroTitle?.textContent}
                color={theme?.sb?.colors?.neutral?.white}
                fontWeight="bold"
                variantOverride={{ [BLOCKS.HEADING_1]: isDesktop ? 'displayL10' : 'displayS20' }}
                data-testid="about-us-hero-title"
              />

              <Typography
                content={aboutUsHeroSubTitle?.textContent}
                className={styles['subtitle']}
                fontWeight={isDesktop ? 'medium' : 'regular'}
                color={theme?.sb?.colors?.neutral?.white}
                data-testid="about-us-hero-subtitle"
                variantOverride={{ [BLOCKS.PARAGRAPH]: 'displayS20' }}
              />
            </div>
          </div>
        </Paper>
      </Grid>
    </Section>
  )
}
