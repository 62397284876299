import {
  AboutCloserStatement,
  AboutEmployeeResourceGroups,
  AboutUsLeadership,
  AboutUsHero,
} from 'pageSections/About'
import { CardGrid } from 'pageSections/CardGrid'
import { CloserStatement } from 'pageSections/CloserStatement'
import {
  FeaturedStory,
  ImageGridSection,
  MemberStoriesCarousel,
  MemberStoriesCloserStatement,
} from 'pageSections/MemberStories'
import { Faqs } from 'pageSections/Faqs'
import { HomeHero, HomeMadLib } from 'pageSections/Home'
import { PoliciesHero, PoliciesBody } from 'pageSections/Policies'
import { ProblemStatement } from 'pageSections/ProblemStatement'
import { ByTheNumbers } from 'pageSections/ByTheNumbers'
import { ProductCards } from 'pageSections/ProductCards'
import { ValueProps } from 'pageSections/ValueProps'
import { AboutUsVideo } from 'pageSections/About/AboutUsVideo'
import { MoreResourcesCarousel } from 'pageSections/MoreResourcesCarousel'
import { TestimonialCarousel } from 'pageSections/TestimonialCarousel'
import { ProductHero } from 'pageSections/ProductHero'
import { StoryBlocks } from 'pageSections/StoryBlocks/StoryBlocks'

import { PressHero, FeaturedPressCarousel } from 'pageSections/Press'
import { PageTitle } from 'pageSections/PageTitle'
import { StickySubNavWrapper } from 'pageSections/StickySubNavWrapper'
import { PressKitDownload } from 'pageSections/PressKitSection'
import { PaginatedStoryGrid } from 'pageSections/PaginatedStoryGrid'
import { VideoDialog } from 'components/VideoDialog'
import { HeroSection } from 'pageSections/HeroSection'

// values in the componentMap need to match dropdown options in Contentful
export const pageSectionTypes = {
  AboutCloserStatement,
  AboutEmployeeResourceGroups,
  AboutUsHero,
  AboutUsLeadership,
  AboutUsVideo,
  ByTheNumbers,
  CardGrid,
  CloserStatement,
  Faqs,
  FeaturedPressCarousel,
  FeaturedStory,
  HeroSection,
  HomeHero,
  HomeMadLib,
  ImageGridSection,
  MemberStoriesCarousel,
  MemberStoriesCloserStatement,
  MoreResourcesCarousel,
  PageTitle,
  PaginatedStoryGrid,
  PoliciesBody,
  PoliciesHero,
  PressHero,
  PressKitDownload,
  ProblemStatement,
  ProductCards,
  ProductHero,
  StickySubNavWrapper,
  StoryBlocks,
  TestimonialCarousel,
  ValueProps,
  VideoDialog,
}
