import {
  Typography as SunbeamTypography,
  Select as SunbeamSelect,
  MenuItem as SunbeamMenuItem,
} from '@ffn/sunbeam'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import styles from './MadLib.module.scss'

function MadLibSelect({ options = [], value, onChange, isMobile, dataTestId }) {
  return (
    <SunbeamSelect
      displayEmpty={true}
      IconComponent={KeyboardArrowDownIcon}
      className={styles['select-sun']}
      classes={{
        icon: styles['select-icon'],
        nativeInput: {
          borderBottom: 'none',
        },
      }}
      variant="standard"
      value={value}
      onChange={(e) => {
        onChange(e.target.value)
      }}
      fullWidth={isMobile}
      data-testid={dataTestId}
    >
      {options.map(({ label, value }, idx) => (
        <SunbeamMenuItem key={label} value={value} autoFocus={idx === 0}>
          <SunbeamTypography
            variant={!isMobile ? 'displayM20' : 'displayS20'}
            fontWeight={!isMobile ? 'regular' : 'bold'}
            color="primary"
            className={styles['select-text']}
          >
            {label}
          </SunbeamTypography>
        </SunbeamMenuItem>
      ))}
    </SunbeamSelect>
  )
}

export { MadLibSelect }
