import styles from './HeroSection.module.scss'
import { useMemoizedContentGetter } from 'utils/contentful'
import Section from 'components/Section'
import { Typography } from 'components/Contentful'
import { BREAKPOINTS, useViewportSmallerThan } from 'utils/mui'
import { Box } from '@ffn/sunbeam'
import { get as _get, debounce as _debounce } from 'lodash-es'
import { HeroMedia } from 'components/HeroMedia'
import { useEffect, useState } from 'react'
import useIsSsr from 'hooks/useIsSsr'

function HeroSection({ content }) {
  const isSsr = useIsSsr()
  const memoizedContent = useMemoizedContentGetter(content, [
    'title',
    'member',
    'message',
    'image',
    'video',
    'video_xs_portrait',
    'video_xs_landscape',
    'video_xs_square',
  ])
  const isTable = useViewportSmallerThan(BREAKPOINTS.lg)
  const isMobile = useViewportSmallerThan(BREAKPOINTS.md)
  const [onViewport, setOnViewport] = useState(0)

  const {
    title,
    member,
    message,
    image,
    video,
    video_xs_portrait,
    video_xs_landscape,
    video_xs_square,
  } = memoizedContent

  const backgroundImage = _get(image, 'mediaContent', '')
  const backgroundImageMobile = _get(image, 'mobileMediaContent', '')
  const backgroundImageAlt = _get(image, 'mediaAltText', member?.textContent)
  const [backgroundVideo, setBackgroundVideo] = useState(null)
  const backgroundVideoFull = _get(video, 'source', null)
  const backgroundPortrait = _get(video_xs_portrait, 'source', null)
  const backgroundLandscape = _get(video_xs_landscape, 'source', null)
  const backgroundSquare = _get(video_xs_square, 'source', null)

  useEffect(() => {
    const viewPortFn = () =>
      window.innerWidth === window.innerHeight ? 0 : window.innerWidth > window.innerHeight ? 1 : 2
    setOnViewport(viewPortFn())
    const handleResize = _debounce(() => setOnViewport(viewPortFn()), 250, { maxWait: 250 })
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isSsr])

  useEffect(() => {
    let video = null
    if (onViewport === 0) {
      video = backgroundSquare
    } else if (onViewport === 1) {
      video = backgroundLandscape
    } else {
      video = backgroundPortrait
    }
    setBackgroundVideo(video ?? backgroundVideoFull)
  }, [onViewport, backgroundVideoFull, backgroundSquare, backgroundLandscape, backgroundPortrait])

  return (
    <Section className={styles['hero-section']} contain={false}>
      <HeroMedia
        className={styles['hero-section-media']}
        image={isMobile ? backgroundImageMobile : backgroundImage}
        video={isTable ? backgroundVideo : backgroundVideoFull}
        alt={backgroundImageAlt}
      >
        <Box className={styles['hero-section-info']}>
          <Box className={styles['hero-section-info-content']}>
            <Typography
              content={title.textContent}
              fontWeight={isTable ? 'bold' : 'medium'}
              variant={isTable ? 'displayS11' : 'displayS20'}
            />
            <Typography
              content={member.textContent}
              fontWeight="bold"
              variant={isTable ? 'displayM10' : 'displayL10'}
              component="h1"
            />
            <Typography
              className={styles['hero-section-text-content']}
              content={message.textContent}
              variant={isTable ? 'displayXS30' : 'displayS10'}
              lineHeight="160%" // TODO alert and update Sunbeam
            />
          </Box>
        </Box>
      </HeroMedia>
    </Section>
  )
}

export { HeroSection }
