import { useState, useRef } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { Grid, Typography as SunbeamTypography, Link } from '@ffn/sunbeam'
import { ArrowRight } from 'react-feather'
import { MadLibResultsCards } from './MadLibResultsCards'

import styles from './MadLib.module.scss'

function MadLibResults({
  onResetClick,
  resetLinkText,
  resultsMap,
  lineOneOptions,
  lineTwoOptions,
  lineOneValue,
  lineTwoValue,
  isMobile,
  ...resultCardProps
}) {
  const [mobileExpanded, setMobileExpanded] = useState(false)
  const containerRef = useRef()

  const lineOneStatement = `${lineOneOptions?.leadingText} ${
    lineOneOptions.dropDownOptions.find((option) => option.value === lineOneValue).label
  }`

  const lineTwoStatement = `${lineTwoOptions?.leadingText} ${
    lineTwoOptions.dropDownOptions.find((option) => option.value === lineTwoValue).label
  }`

  // Find the recommended result by comparing the Mad Lib line 2 selected value with the result
  // mapping from contentful.
  const recommended = Object.keys(resultsMap).find((key) =>
    resultsMap[key]?.conditions?.line2Value.includes(lineTwoValue)
  )

  function onResultsToggle() {
    // let
    if (mobileExpanded) {
      // The distance from the top plus an additional 70 to account for the mobile header and margin
      const distanceFromTop = window.scrollY + containerRef.current.getBoundingClientRect().top - 70

      window.scrollTo({ top: distanceFromTop, behavior: 'smooth' })
    } else {
      // The distance to the next result item after it is revealed. Extra 160 to line up with
      // hidden elements that have margins/padding.
      const distanceFromTop =
        window.scrollY + containerRef.current.getBoundingClientRect().bottom - 160

      window.scrollTo({ top: distanceFromTop, behavior: 'smooth' })
    }
    setMobileExpanded(!mobileExpanded)
  }

  return (
    <Grid
      className={styles['mad-lib-results']}
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      container
      ref={containerRef}
      data-testid="mad-lib-results"
    >
      <SunbeamTypography
        className={styles['mad-lib-title']}
        variant={isMobile ? 'displayM10' : 'displayM20'}
        fontWeight="medium"
        component="h2"
        data-testid="mad-lib-results-title"
      >
        {lineOneStatement}. {lineTwoStatement}.
      </SunbeamTypography>

      <MadLibResultsCards
        recommended={recommended}
        mobileExpanded={mobileExpanded}
        onResultsToggle={onResultsToggle}
        {...resultCardProps}
      />
      {onResetClick && (
        <Link
          className={styles['reset-link']}
          underline="hover"
          fontWeight="bold"
          variant="displayS10"
          onClick={() => onResetClick()}
          data-testid="mad-lib-results-reset"
        >
          <span>{resetLinkText}</span> <ArrowRight />
        </Link>
      )}
      {recommended && (
        <Link className={styles['mobile-expand-link']} onClick={() => onResultsToggle()}>
          <SunbeamTypography variant="displayXS30" fontWeight="bold">
            {`See ${mobileExpanded ? 'less' : 'more'}`}
          </SunbeamTypography>
          {mobileExpanded ? <ChevronUp /> : <ChevronDown />}
        </Link>
      )}
    </Grid>
  )
}

export { MadLibResults }
