import styles from 'components/Cards/CardValueProp/CardValueProp.module.scss'
import { CardMedia as SunbeamCardMedia, Box } from '@ffn/sunbeam'
import { MediaImage, Typography } from 'components/Contentful'

function CardValueProp({ content, breakline, isMobile }) {
  const { cardTitleText, cardText, cardImage } = content?.fields || {}

  return (
    <Box
      variant="none"
      data-testid="card-container"
      className={[styles['card'], breakline && styles['breakline']]}
    >
      <Box className={styles['card-img']}>
        {cardImage && (
          <SunbeamCardMedia data-testid="sunbeam-card-media">
            <MediaImage
              data-testid="card-media-image"
              layout="fixed"
              width={isMobile ? 55 : 80}
              height={isMobile ? 55 : 80}
              content={cardImage}
            />
          </SunbeamCardMedia>
        )}
      </Box>
      <Box className={styles['card-info']}>
        {cardTitleText && (
          <Typography
            data-testid="card-title"
            variant={'displayS20'}
            fontWeight="medium"
            className={styles['card-text-title']}
            content={cardTitleText}
          />
        )}

        {cardText && (
          <Typography
            data-testid="card-subtitle"
            className={styles['card-text-subtitle']}
            variant={'bodyS30'}
            component="span"
            content={cardText}
          />
        )}
      </Box>
    </Box>
  )
}

export { CardValueProp }
