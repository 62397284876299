import React from 'react'
import { pageSectionTypes } from './pageSectionTypes'
import { sectionContentTypes } from './sectionContentTypes'
import { ChildComponentGenerator } from './ChildComponentGenerator'
import { Grid } from '@ffn/sunbeam'

export function PageHandler({ pageContent }) {
  return (pageContent?.fields?.sections || []).map((section) => {
    return (
      <ChildComponentGenerator
        contentTypes={pageSectionTypes}
        uiComponent={section.fields.uiComponent}
        {...section}
        key={section?.sys?.id}
      />
    )
  })
}

export function SectionHandler({ sectionContents }) {
  return (sectionContents || []).map((sectionContent) => {
    return (
      <ChildComponentGenerator
        contentTypes={sectionContentTypes}
        uiComponent={sectionContent.fields.uiComponent}
        {...sectionContent}
        key={sectionContent?.sys?.id}
      />
    )
  })
}

export function GridHandler({ gridContents, columns, ...gridProps }) {
  return (gridContents || []).map((gridContent, index) => {
    return (
      <Grid item xs={12 / columns} key={`${gridContent?.sys?.id}-${index}`} {...gridProps}>
        <ChildComponentGenerator
          contentTypes={sectionContentTypes}
          uiComponent={gridContent.fields.uiComponent}
          {...gridContent}
        />
      </Grid>
    )
  })
}
